import React from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout/Layout";
import SEO from "../components/seo/Seo";
import Subscribe from "../components/subscribe/Subscribe";
import i18nContext from "../components/i18n/i18n.context";
import Page from "../components/page/Page";
import Helmet from "react-helmet";

import { recaptchaKey } from "../conf/conf";

function SubscribePage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "en" }}>
      <Layout>
        <SEO
          keywords={["gatsby", "react"]}
          location={location}
          title="Subscribe"
        />
        <Page>
          <Helmet>
            <script
              src={`https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`}
            />
          </Helmet>
          <Subscribe />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

SubscribePage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default SubscribePage;
